import { Button, Flex, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconWand } from '@tabler/icons-react';
import { EmptyState } from 'components';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { DataGenerationRouteParams, ROUTES } from 'routes/routes.config';
import { AiModelType, isAiModelType, useLastAiDataGenSamples, useLatestAiDataSourceByType } from 'stores/aiPlatform';
import { useFlow } from 'stores/flows';
import { PageTitle } from '../components/PageTitle/PageTitle';
import { useCompletionModal } from './CompletionModal/useCompletionModal';
import { DataGenerationCard, cardTestIds } from './components';
import { DataGenerationActions } from './components/DataGenerationActions/DataGenerationActions';
import { TextDataGenerationSettingsModal } from './TextDataGenerationSettingsModal/TextDataGenerationSettingsModal';
import { VoiceDataGenerationSettingsModal } from './VoiceDataGenerationSettingsModal/VoiceDataGenerationSettingsModal';

export const testIds = {
  wrapper: 'data-generation-page',
  generateButton: 'data-generation-page-new-generation-button',
  card: cardTestIds,
};

export const DataGenerationPage = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as DataGenerationRouteParams;
  const flow = useFlow(flowId);
  const [params] = useSearchParams();
  const modelType = params.get('modelType') ?? '';
  const isValidModelType = isAiModelType(modelType);
  const aiDataSource = useLatestAiDataSourceByType(isValidModelType ? modelType : undefined);
  const [opened, { open, close }] = useDisclosure(false);
  const samples = useLastAiDataGenSamples(aiDataSource?.id);
  const CompletionModal = useCompletionModal({
    isSamplesGeneration: aiDataSource?.isSamplesGeneration,
    status: aiDataSource?.status,
  });

  if (!isValidModelType) {
    return (
      <Navigate
        to={{
          pathname: ROUTES.AI_PLATFORM(customerId, flowId).DATA_GENERATION,
          search: `?modelType=${AiModelType.NLP}`,
        }}
        replace
      />
    );
  }

  const pageTitle = t(`dataGenerationPage.pageTitle.${modelType}`);

  return (
    <Stack p='xl' gap='md' className='h-full' pos='relative' pb={50} data-testid={testIds.wrapper}>
      <Flex align='center' justify='space-between'>
        <PageTitle title={pageTitle} subtitle={flow.name} />
        <Button leftSection={<IconWand />} onClick={open} data-testid={testIds.generateButton}>
          {t('dataGenerationPage.generateButton')}
        </Button>
      </Flex>
      {aiDataSource ? (
        <>
          <DataGenerationCard aiDataSource={aiDataSource} samples={samples} onRetry={open} />
          <DataGenerationActions aiDataSource={aiDataSource} />
        </>
      ) : (
        <EmptyState
          title={t('dataGenerationPage.emptyState.title')}
          message={t('dataGenerationPage.emptyState.message')}
          py={80}
          flex={1}
          withBorder
        />
      )}
      {modelType === AiModelType.NLP && <TextDataGenerationSettingsModal opened={opened} onClose={close} />}
      {modelType === AiModelType.ASR && <VoiceDataGenerationSettingsModal opened={opened} onClose={close} />}
      {CompletionModal}
    </Stack>
  );
};
