import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import { AiDataSourceStatus } from 'stores/aiPlatform';
import { CompletionModal } from './CompletionModal';

export interface CompletionModalProps {
  isSamplesGeneration?: boolean;
  status?: AiDataSourceStatus;
}

export const useCompletionModal = ({
  isSamplesGeneration = false,
  status = AiDataSourceStatus.IN_PROGRESS,
}: CompletionModalProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if ((status === AiDataSourceStatus.COMPLETED || status === AiDataSourceStatus.FAILED) && !isSamplesGeneration) {
      open();
    } else if (opened) {
      close();
    }
  }, [status, isSamplesGeneration]);

  const completionModal = (
    <CompletionModal opened={opened} onClose={close} success={status === AiDataSourceStatus.COMPLETED} />
  );

  return completionModal;
};
