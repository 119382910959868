import { FC } from 'react';
import { Flex, HoverCard, NavLink, Text, ThemeIcon } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import i18n from 'services/i18n';
import { CopyButton } from 'components/CopyButton/CopyButton';
import { AiModelDataTooltip } from 'stores/aiPlatform/aiPublishedModels';
import { testIds } from '../AiModelDataTable';

type TooltipModalProps = { data: AiModelDataTooltip; linkText: string };

type DataTooltipHeaderProps = TooltipModalProps & { headerTitle: string; id: string };

export const DataTooltipHeader: FC<DataTooltipHeaderProps> = ({ data, headerTitle, id, linkText }) => (
  <Flex gap={8} align='center' data-testid={testIds.getTooltipTestId(id)}>
    {headerTitle}
    <HoverCard closeDelay={200}>
      <HoverCard.Target>
        <ThemeIcon color='red' size='sm' variant='transparent'>
          <IconInfoCircle cursor='pointer' data-testid={`${testIds.dataTooltip}-icon-${id}`} />
        </ThemeIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Flex
          mih={50}
          gap='md'
          justify='flex-start'
          align='flex-start'
          direction='column'
          wrap='wrap'
          data-testid={testIds.dataTooltipModal}
        >
          <Flex gap={8}>
            <Text fw={500}>{i18n.t('publishPage.modelDataTable.headers.tooltip.createdBy')}:</Text>
            <Text>{data.createdBy}</Text>
          </Flex>
          <Flex gap={8}>
            <Text fw={500}>
              <span>{i18n.t('publishPage.modelDataTable.headers.tooltip.creationDate')}:</span>
            </Text>
            <Text>{data.creationDate}</Text>
          </Flex>
          <Flex gap={8}>
            <NavLink p={0} href={data.link} target='_blank' label={<Text fw={500}>Go to {linkText}</Text>} />
            <CopyButton value={data.link} copyText='Copy URL' />
          </Flex>
        </Flex>
      </HoverCard.Dropdown>
    </HoverCard>
  </Flex>
);
