import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';
import { createStoreHook } from '@aiola/frontend';
import { useShallow } from 'zustand/react/shallow';
import { AiPublishedModel, AiModelId } from './aiPublishedModels.types';
import { getMockAiPublishedModels } from './aiPublishedModels.mocks';

interface AiPublishedModelsState {
  loading: boolean;
  aiPublishedModels: Record<AiModelId, AiPublishedModel>;
}

interface AiPublishedModelsActions {
  fetchPublishedModels: (ofsset: number) => void;
  reset: () => void;
}

const mockedModels = getMockAiPublishedModels(100).reduce(
  (acc, model) => ({ ...acc, [model.publishExecutionId]: model }),
  {},
);

const initialState: AiPublishedModelsState = {
  loading: false,
  aiPublishedModels: mockedModels,
};

export const aiPublishedModelStore = create(
  immer<AiPublishedModelsState & AiPublishedModelsActions>((set) => ({
    ...initialState,
    reset: () => {
      set({ loading: false, aiPublishedModels: {} });
    },
    fetchPublishedModels: (_offset: number) => {
      set({ loading: true });
      setTimeout(() => {
        set({
          aiPublishedModels: mockedModels,
          loading: false,
        });
      }, 1000);
    },
  })),
);

export const useAiPublishedModelStore = createStoreHook<AiPublishedModelsState & AiPublishedModelsActions>({
  store: aiPublishedModelStore,
  useShallow,
});
