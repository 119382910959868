import { ModalBase } from 'components';
import { metricsTableTestIds } from 'pages/aiPlatform/components';
import { AiModelData } from 'stores/aiPlatform/aiPublishedModels';
import { testIds as tableHeaderTestIds } from 'pages/aiPlatform/TrainingPipelinePage/components/TestingContent/TestingContentHeader';
import { AiModelDataTable } from './AiModelDataTable/AiModelDataTable';

interface ModelDataModalProps {
  opened: boolean;
  data: AiModelData;
  onClose: () => void;
}

export const testIds = {
  modal: 'evaluation-results-modal',
  table: metricsTableTestIds,
  tableHeader: tableHeaderTestIds,
};

export const ModelDataModal = ({ opened, data, onClose }: ModelDataModalProps) => (
  <ModalBase
    opened={opened}
    title=''
    size='auto'
    centered
    headerProps={{ mb: 0 }}
    bodyProps={{ px: 'lg', className: 'flex flex-col min-h-0' }}
    contentProps={{ className: 'flex flex-col' }}
    onClose={onClose}
    data-testid={testIds.modal}
  >
    <AiModelDataTable
      data={[data]}
      trainingInfo={data.trainingInfo}
      dataGenerationInfo={data.dataGenerationInfo}
      classNames={{
        paper: 'flex flex-col',
      }}
    />
  </ModalBase>
);
