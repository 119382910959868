import { useMemo } from 'react';
import { AiModelType } from '../aiModels';
import { useAiDataSourcesStore } from './aiDataSources.store';
import {
  AiDataSource,
  AiDataSourceId,
  AiDataSourceSamplesType,
  AiDataTextSample,
  AiDataVoiceSample,
} from './aiDataSources.types';

export function useAiDataSource(id: AiDataSourceId): AiDataSource | undefined {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return aiDataSources[id];
}

export function useAiDataSourcesAvailability(): boolean {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return Object.keys(aiDataSources).length > 0;
}

export function useLatestAiDataSourceByType(type: AiModelType | undefined): AiDataSource | undefined {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return useMemo(
    () =>
      type &&
      Object.values(aiDataSources)
        .filter(({ samplesType }) =>
          [
            AiDataSourceSamplesType.BOTH,
            type === AiModelType.NLP ? AiDataSourceSamplesType.TEXT : AiDataSourceSamplesType.VOICE,
          ].includes(samplesType),
        )
        .sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0))[0],
    [aiDataSources, type],
  );
}

export function useLastAiDataGenSamples(id?: AiDataSourceId): AiDataTextSample[] | AiDataVoiceSample[] | [] {
  const { aiSamples } = useAiDataSourcesStore(['aiSamples']);
  return id ? aiSamples[id] : [];
}
