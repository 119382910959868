import { Button, Tooltip } from '@mantine/core';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { AiPublishedModel } from 'stores/aiPlatform/aiPublishedModels';
import { useDisclosure } from '@mantine/hooks';
import { ModelDataModal, testIds as modalTestIds } from './ModelDataModal';

export const testIds = {
  viewButton: {
    button: 'published-model-table-row-action-view-button',
    tooltip: 'published-model-table-row-action-view-tooltip',
  },
  modal: modalTestIds,
};

export const PublishPageTableRowActions: NonNullable<MRT_TableOptions<AiPublishedModel>['renderRowActions']> = ({
  row,
}) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Tooltip label={t('publishPage.table.modelDataTooltip')} data-testid={testIds.viewButton.tooltip}>
        <Button onClick={open} data-testid={testIds.viewButton.button}>
          {t('publishPage.table.modelData')}
        </Button>
      </Tooltip>
      <ModelDataModal opened={opened} onClose={close} {...{ data: row.original.modelData }} />
    </>
  );
};
