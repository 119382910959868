import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { MRT_Row, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import {
  AiModelType,
  AiPublishedModel,
  useAiModelsByType,
  useLastPublishedModel,
} from 'stores/aiPlatform/aiPublishedModels';
import { useAiPublishedModelStore } from 'stores/aiPlatform/aiPublishedModels/aiPublishedModels.store';
import { columns, testIds as columnsTestIds } from './PublishPageTable.columns';
import { publishPageTableDefs, initialSorting, initialPagination } from './PublishPageTable.defs';
import {
  PublishPageTableEmptyState,
  PublishPageTableRowActions,
  emptyStateTestIds,
  rowActionsTestIds,
} from './components';

import classes from './PublishPageTable.module.css';

export const testIds = {
  table: 'published-model-table',
  getRowTestId: (id: string) => `published-model-table-row-${id}`,
  rowRegex: /published-model-table-row-[^-]+$/,
  emptyState: emptyStateTestIds,
  rowActions: rowActionsTestIds,
  columns: columnsTestIds,
  pagination: 'ai-published-pagination',
};

type PublishPageTableProps = {
  total?: number;
  classNames?: {
    table?: string;
  };
};

export const PublishPageTable: FC<PublishPageTableProps> = ({ total = 5, classNames }) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const [pagination, setPagination] = useState(initialPagination);
  const modelType = params.get('modelType') as AiModelType;
  const models = useAiModelsByType(modelType);
  const { fetchPublishedModels, loading } = useAiPublishedModelStore(['fetchPublishedModels', 'loading']);

  const lastPublishedModel = useLastPublishedModel(modelType);

  const isLastPublishedModel = (row: MRT_Row<AiPublishedModel>) =>
    row.original.publishExecutionId === lastPublishedModel?.publishExecutionId;

  useEffect(() => {
    if (pagination.pageIndex !== initialPagination.pageIndex) {
      fetchPublishedModels(pagination.pageSize * pagination.pageIndex);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const table = useMantineReactTable({
    data: models,
    columns,
    ...publishPageTableDefs,
    enableBottomToolbar: true,
    mantineTableBodyRowProps: ({ row }) => ({
      className: cn('cursor-pointer', { [classes.lastPublishedRow]: isLastPublishedModel(row) }),
      'data-testid': testIds.getRowTestId(row.id),
      ...(isLastPublishedModel(row) ? { 'data-last-published-text': t('publishPage.table.published') } : {}),
    }),
    mantinePaginationProps: () => ({
      showRowsPerPage: false,
      total,
      'data-testid': testIds.pagination,
    }),
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    renderEmptyRowsFallback: (props) => <PublishPageTableEmptyState {...props} />,
    renderRowActions: (props) => <PublishPageTableRowActions {...props} />,
    onPaginationChange: setPagination,
    mantinePaperProps: { className: 'h-auto max-h-[95%]' },
    mantineTableContainerProps: { className: classNames?.table ?? 'max-h-[95%]' },
    mantineBottomToolbarProps: { className: 'min-h-14 h-14' },
    state: { pagination, isLoading: loading, showLoadingOverlay: loading },
    initialState: { sorting: initialSorting },
  });

  return (
    <>
      <MantineReactTable table={table} />
      <div
        style={{
          position: 'absolute',
          top: '60%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(25deg)',

          color: 'red',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Mock Samples
      </div>
    </>
  );
};
