import i18n from 'services/i18n';
import { MantineColor } from '@mantine/core';
import { AiModelEnvironment } from 'stores/aiPlatform/aiPublishedModels';

export const envLabels: Record<AiModelEnvironment, string> = {
  [AiModelEnvironment.INTERNAL_AUTOMATION]: i18n.t('common.env.internal_automation'),
  [AiModelEnvironment.INTERNAL_DEV]: i18n.t('common.env.internal_dev'),
  [AiModelEnvironment.INTERNAL_QA]: i18n.t('common.env.internal_qa'),
  [AiModelEnvironment.PROD]: i18n.t('common.env.prod'),
} as const;

export const envColors: Record<AiModelEnvironment, MantineColor> = {
  [AiModelEnvironment.INTERNAL_AUTOMATION]: 'gray',
  [AiModelEnvironment.INTERNAL_DEV]: 'blue',
  [AiModelEnvironment.INTERNAL_QA]: 'yellow',
  [AiModelEnvironment.PROD]: 'red',
} as const;
