import { MRT_TableOptions } from 'mantine-react-table';
import { AiModelData } from 'stores/aiPlatform/aiPublishedModels';

export const metricsTableDefs = {
  getRowId: ({ trainingExecutionId }) => `${trainingExecutionId}`,
  enableSorting: false,
  enableStickyHeader: false,
  enableColumnActions: false,
  enableRowSelection: false,
  enableRowPinning: false,
  enableTopToolbar: false,
  enableBottomToolbar: false,
  rowPinningDisplayMode: 'select-bottom',
  manualPagination: false,
  enablePagination: false,
  layoutMode: 'semantic',
  mantineTableBodyCellProps: { py: 'xs' },
} satisfies Partial<MRT_TableOptions<AiModelData>>;
