import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { CLASSIC_DATE_TIME_FORMAT } from 'consts';
import { getMockMiniUser } from 'stores/auth';
import { AiPublishedModel, AiModelType, AiModelEnvironment } from './aiPublishedModels.types';
import { AiModelStatus } from '../aiModels';

export function getMockAiModel(overrides: Partial<AiPublishedModel> = {}): AiPublishedModel {
  return {
    publishExecutionId: nanoid(),
    publishedDate: Date.now(),
    publishedBy: getMockMiniUser(),
    status: AiModelStatus.COMPLETED,
    type: AiModelType.NLP,
    modelData: getMockModelData(),
    environment: AiModelEnvironment.INTERNAL_AUTOMATION,
    ...overrides,
  };
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function getMockAiPublishedModels(
  count: number = 5,
  overrides: Partial<AiPublishedModel> = {},
): AiPublishedModel[] {
  return Array.from({ length: count }, (_, i) =>
    getMockAiModel({
      status: Object.values(AiModelStatus)[getRandomInt(0, 4)] ?? AiModelStatus.COMPLETED,
      environment: Object.values(AiModelEnvironment)[getRandomInt(0, 4)] ?? AiModelEnvironment.INTERNAL_AUTOMATION,
      publishedDate: dayjs().subtract(i, 'hour').valueOf(),
      type: Object.values(AiModelType)[getRandomInt(0, 2)],
      ...overrides,
    }),
  );
}

export function getMockModelData() {
  return {
    language: 'en',
    trainingExecutionId: nanoid(),
    trainingInfo: {
      createdBy: 'Arthas Menethil',
      creationDate: dayjs().subtract(1, 'hour').format(CLASSIC_DATE_TIME_FORMAT),
      link: '/',
    },
    dataGenerationId: nanoid(),
    dataGenerationInfo: {
      createdBy: 'Arthas Menethil',
      creationDate: dayjs().subtract(1, 'hour').format(CLASSIC_DATE_TIME_FORMAT),
      link: '/',
    },
    flowVersion: nanoid(),
    flowId: nanoid(),
    tenant: 'aiola',
  };
}
