import { UserIdentity } from '@flow/flow-backend-types';
import { AiModelStatus } from '../aiModels';

export enum AiModelEnvironment {
  INTERNAL_DEV = 'internal-dev',
  INTERNAL_QA = 'internal-qa',
  INTERNAL_AUTOMATION = 'internal-automation',
  PROD = 'prod',
}

export enum AiModelType {
  NLP = 'nlp',
  ASR = 'asr',
}

export type AiModelId = string;
export type AiModelDataTooltip = {
  createdBy: string;
  creationDate: string;
  link: string;
};

export type AiModelData = {
  language: string;
  trainingExecutionId: string;
  dataGenerationId: string;
  flowVersion: string;
  flowId: string;
  tenant: string;
  trainingInfo: AiModelDataTooltip;
  dataGenerationInfo: AiModelDataTooltip;
};
export interface AiPublishedModel<Type extends AiModelType = AiModelType> {
  publishExecutionId: AiModelId;
  publishedDate: number;
  publishedBy: UserIdentity;
  status: AiModelStatus;
  environment: AiModelEnvironment;
  type: Type;
  modelData: AiModelData;
}

export interface AiModelSubsciptionOptions {
  unsubscribeOnFinish?: boolean;
  onComplete?: () => void;
}
