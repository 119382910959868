import { Button, Stack, Text } from '@mantine/core';
import { ModalBase } from 'components';

import { IconCircleCheckFilled, IconCircleXFilled } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface CompletionModalProps {
  opened: boolean;
  onClose: () => void;
  success: boolean;
}
export const testIds = {
  wrapper: 'text-data-generation-completion-modal',
  icon: 'icon-data-generation-completion-modal',
  title: 'title-data-generation-completion-modal',
  message: 'message-data-generation-completion-modal',
  button: 'button-data-generation-completion-modal',
};

export const CompletionModal = ({ opened, onClose, success = false }: CompletionModalProps) => {
  const { t } = useTranslation();

  const title = success
    ? t('dataGenerationPage.completionModal.title')
    : t('dataGenerationPage.completionModal.titleError');
  const message = success
    ? t('dataGenerationPage.completionModal.message')
    : t('dataGenerationPage.completionModal.messageError');
  const button = success
    ? t('dataGenerationPage.completionModal.ctaPositive')
    : t('dataGenerationPage.completionModal.ctaNegative');

  const IconComponent = success ? (
    <IconCircleCheckFilled size={80} fill='var(--mantine-color-anchor)' data-testid={testIds.icon} />
  ) : (
    <IconCircleXFilled size={80} fill='var(--mantine-color-red-7)' data-testid={testIds.icon} />
  );

  return (
    <ModalBase opened={opened} onClose={onClose} data-testid={testIds.wrapper} title=''>
      <Stack align='center' gap={20} pb={40}>
        {IconComponent}
        <Text fw={600} data-testid={testIds.title}>
          {title}
        </Text>
        <Text c='var(--mantine-color-gray-5)' fw={400} data-testid={testIds.message} style={{ textAlign: 'center' }}>
          {message}
        </Text>
        <Button
          variant='submit'
          bg={success ? 'var(--mantine-color-anchor)' : 'var(--mantine-color-gray-7)'}
          onClick={onClose}
        >
          {button}
        </Button>
      </Stack>
    </ModalBase>
  );
};
