import { MRT_ColumnDef } from 'mantine-react-table';
import { Flex, Text } from '@mantine/core';
import i18n from 'services/i18n';
import { CopyButton } from 'components/CopyButton/CopyButton';
import { AiModelData, AiModelDataTooltip } from 'stores/aiPlatform/aiPublishedModels';
import { DataTooltipHeader } from './cells/DataTooltipHeader';
import { testIds } from './AiModelDataTable';

export const getColumns: ({
  trainingInfo,
  dataGenerationInfo,
}: {
  trainingInfo: AiModelDataTooltip;
  dataGenerationInfo: AiModelDataTooltip;
}) => MRT_ColumnDef<AiModelData>[] = ({ trainingInfo, dataGenerationInfo }) => [
  {
    accessorKey: 'language',
    header: i18n.t('publishPage.modelDataTable.headers.language'),
    grow: 0,
  },
  {
    accessorKey: 'trainingExecutionId',
    Header: ({ header }) => (
      <DataTooltipHeader
        {...{
          id: header.id,
          headerTitle: i18n.t('publishPage.modelDataTable.headers.trainingExecutionId'),
          data: trainingInfo,
          linkText: i18n.t('publishPage.modelDataTable.headers.tooltip.trainingLinkText'),
        }}
      />
    ),
    header: '',
    accessorFn: ({ trainingExecutionId }) => `${trainingExecutionId}`,
    grow: 1,
    Cell: ({ cell }) => (
      <Flex gap={8} align='center'>
        <Text data-testid={testIds.getCopyCellId('trainingExecutionId')} style={{ whiteSpace: 'pre' }}>
          {cell.getValue<number>()}
        </Text>
        <CopyButton
          data-testid={testIds.getCopyButtonId('trainingExecutionId')}
          value={cell.getValue<number>().toString()}
        />
      </Flex>
    ),
  },
  {
    accessorKey: 'dataGenerationId',
    accessorFn: ({ dataGenerationId }) => `${dataGenerationId}`,
    grow: 1,
    Header: ({ header }) => (
      <DataTooltipHeader
        {...{
          id: header.id,
          headerTitle: i18n.t('publishPage.modelDataTable.headers.dataGenerationId'),
          data: dataGenerationInfo,
          linkText: i18n.t('publishPage.modelDataTable.headers.tooltip.dataGenerationLinkText'),
        }}
      />
    ),
    header: '',
    Cell: ({ cell }) => (
      <Flex gap={8} align='center'>
        <Text data-testid={testIds.getCopyCellId('dataGenerationId')} style={{ whiteSpace: 'pre' }}>
          {cell.getValue<number>()}
        </Text>
        <CopyButton
          data-testid={testIds.getCopyButtonId('dataGenerationId')}
          value={cell.getValue<number>().toString()}
        />
      </Flex>
    ),
  },
  {
    accessorKey: 'flowVersion',
    header: i18n.t('publishPage.modelDataTable.headers.flowVersion'),
    accessorFn: ({ flowVersion }) => <Text style={{ whiteSpace: 'pre' }}>{flowVersion}</Text>,
    grow: 1,
  },
  {
    accessorKey: 'flowId',
    header: i18n.t('publishPage.modelDataTable.headers.flowId'),
    accessorFn: ({ flowId }) => <Text style={{ whiteSpace: 'pre' }}>{flowId}</Text>,
    grow: 1,
  },
  {
    accessorKey: 'tenant',
    header: i18n.t('publishPage.modelDataTable.headers.tenant'),
    grow: 0,
  },
];
