import { Divider, Paper } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  AiDataSource,
  AiDataSourceSamplesType,
  AiDataSourceStatus,
  AiDataSourceStep,
  AiDataTextSample,
  AiDataVoiceSample,
  useAiDataSourcesStore,
} from 'stores/aiPlatform';
import {
  DataGenTextSamplesTable,
  testIds as samplesTableTestIds,
} from '../DataGenSamplesTable/DataGenTextSamplesTable';
import { DataGenVoiceSamplesTable } from '../DataGenVoiceSamplesTable/DataGenVoiceSamplesTable';
import { DataGenerationCardHeader, testIds as headerTestIds } from './DataGenerationCardHeader';
import { DataGenerationErrorModal, testIds as errorModalTestIds } from './DataGenerationErrorModal';
import { DataGenerationProgressbar, testIds as progressTestIds } from './DataGenerationProgressbar';

interface DataGenerationCardProps {
  aiDataSource: AiDataSource;
  samples: AiDataTextSample[] | AiDataVoiceSample[] | undefined;
  onRetry: () => void;
}

export const testIds = {
  header: headerTestIds,
  progress: progressTestIds,
  samplesTable: samplesTableTestIds,
  errorModal: errorModalTestIds,
};

export const DataGenerationCard = memo(({ aiDataSource, samples, onRetry }: DataGenerationCardProps) => {
  const { subscribeToAiDataSource } = useAiDataSourcesStore(['subscribeToAiDataSource']);
  const percent = useMemo(() => statusToPercent(aiDataSource.step), [aiDataSource.step]);
  const elementRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [isError, { open: showError, close: hideError }] = useDisclosure();

  const handleGenerationRetry = useCallback(() => {
    hideError();
    onRetry();
  }, [onRetry, hideError]);

  useLayoutEffect(() => {
    if (elementRef.current) {
      setWidth((elementRef.current as HTMLElement).offsetWidth); // TODO - use custom mantine table footer in the table and then remove this magic number
    }
  }, []);

  useEffect(() => {
    const unsubscribeFromAiDataSource = subscribeToAiDataSource(aiDataSource.id, {
      unsubscribeOnFinish: true,
      onFail: showError,
    });
    return () => {
      unsubscribeFromAiDataSource();
    };
  }, [aiDataSource.id, subscribeToAiDataSource]);

  const { status, isSamplesGeneration, samplesType } = aiDataSource;
  const renderProgressBar = useMemo(() => status === AiDataSourceStatus.IN_PROGRESS, [status]);
  const showTable = useMemo(
    () => isSamplesGeneration && [AiDataSourceStatus.COMPLETED, AiDataSourceStatus.IN_PROGRESS].includes(status),
    [status, isSamplesGeneration],
  );
  const showTextSamplesTable = useMemo(
    () => showTable && samplesType === AiDataSourceSamplesType.TEXT,
    [showTable, samplesType],
  );
  const showVoiceSamplesTable = useMemo(
    () => showTable && samplesType === AiDataSourceSamplesType.VOICE,
    [showTable, samplesType],
  );

  return (
    <Paper withBorder radius={10} p={25} mih={0} h='auto' ref={elementRef}>
      <DataGenerationCardHeader aiDataSource={aiDataSource} />
      <Divider my='sm' className='last:hidden' />
      {renderProgressBar && <DataGenerationProgressbar percent={percent} />}

      {showTextSamplesTable && (
        <DataGenTextSamplesTable
          samples={samples as AiDataTextSample[]}
          isLoading={status !== AiDataSourceStatus.COMPLETED}
          width={width}
        />
      )}
      {showVoiceSamplesTable && (
        <DataGenVoiceSamplesTable
          samples={samples as AiDataVoiceSample[]}
          isLoading={status !== AiDataSourceStatus.COMPLETED}
          width={width}
        />
      )}
      <DataGenerationErrorModal
        opened={isError}
        aiDataSource={aiDataSource}
        onClose={hideError}
        onRetry={handleGenerationRetry}
      />
    </Paper>
  );
});

const statusToPercentMap: Record<AiDataSourceStep, number> = {
  [AiDataSourceStep.GET_FLOW_SETTINGS]: 25,
  [AiDataSourceStep.GENERATE_AND_STORE_TEXT_SAMPLES]: 50,
  [AiDataSourceStep.GENERATE_AND_STORE_SPEECH_SAMPLES]: 70,
  [AiDataSourceStep.PROVISIONING_DATA_FILTERING_NOTEBOOK]: 90,
  [AiDataSourceStep.DATA_GENERATION_DONE]: 100,
  [AiDataSourceStep.UNEXPECTED_ERROR]: 5,
};

function statusToPercent(step: AiDataSourceStep): number {
  return statusToPercentMap[step] ?? statusToPercentMap.UNEXPECTED_ERROR;
}
