import { useMemo } from 'react';
import { useAiPublishedModelStore } from './aiPublishedModels.store';
import { AiPublishedModel, AiModelId, AiModelType } from './aiPublishedModels.types';

export function useAiModel(modelId: AiModelId): AiPublishedModel | undefined {
  return useAiPublishedModelStore((state) => state.aiPublishedModels[modelId]);
}

export function useAiModelsByType<T extends AiModelType>(type: T): AiPublishedModel<T>[] {
  const { aiPublishedModels } = useAiPublishedModelStore(['aiPublishedModels']);
  return useMemo(
    () => Object.values(aiPublishedModels).filter((model): model is AiPublishedModel<T> => model.type === type),
    [aiPublishedModels, type],
  );
}

export function useLastPublishedModel(type: AiModelType): AiPublishedModel | undefined {
  const aiPublishedModels = useAiModelsByType(type);
  return useMemo(() => aiPublishedModels.sort((a, b) => b.publishedDate - a.publishedDate)[0], [aiPublishedModels]);
}
