import { nanoid } from 'nanoid';
import { User, UserRole, MiniUser } from './auth.types';

export function getMockUser(overrides: Partial<User> = {}): User {
  return {
    userId: nanoid(),
    givenName: 'Jaina',
    familyName: 'Proudmoore',
    name: 'Jaina Proudmoore',
    email: 'jp@aiola.com',
    preferredUsername: 'Grand Admiral',
    roles: [UserRole.USER],
    ...overrides,
  };
}

export function getMockMiniUser(overrides: Partial<MiniUser> = {}): MiniUser {
  return {
    userId: nanoid(),
    givenName: 'Arthas',
    familyName: 'Menethil',
    ...overrides,
  };
}
